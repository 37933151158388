import React from "react";
import "./style/circular-dial.scss";
import {GaugeComponent, ValueLabel} from "react-gauge-component";

interface CircularDialProps {
    value: number;
    maxValue: number;
    lessIsBetter?: boolean;
    gradient?: boolean
}

export default class CircularDial extends React.Component<CircularDialProps, any> {
    render() {
        const me = this;
        const subArcs = me.props.lessIsBetter ? [
            {limit: 1 * me.props.maxValue, color: 'var(--green-500)', showTick: true},
            {limit: 1.2 * me.props.maxValue, color: 'var(--yellow-500)', showTick: true},
            {limit: 1.99 * me.props.maxValue, color: 'var(--red-500)', showTick: true}
        ] : [
            {limit: 0.85 * me.props.maxValue, color: 'var(--red-500)', showTick: true},
            {limit: 0.95 * me.props.maxValue, color: 'var(--yellow-500)', showTick: true},
            {limit: 1.5 * me.props.maxValue, color: 'var(--green-500)', showTick: true}
        ];
        return <div className="circular-dial">
            
            <GaugeComponent
                type={"semicircle"}
                style={{width: "100%", padding: 0}}
                id={`gauge-${Math.random().toString(36).substr(2, 4)}`}
                arc={{
                    // gradient: me.props.gradient,
                    width: 0.2,
                    padding: 0.02,
                    subArcs: subArcs
                }}
                maxValue={me.props.lessIsBetter ? me.props.maxValue * 2 : me.props.maxValue * 1.5}
                value={me.props.value}
                labels={{
                    valueLabel: {
                        matchColorWithArc: true,
                    }
                }}
            />
        </div>
    }
}

