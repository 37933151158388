import React from "react";
import {ApiEngine} from "api-engine";
import "./style/trading-points-page.scss";
import TradingPointComponent from "../../domains/trading_points/common/TradingPointComponent";

interface TradingPointsPageContentProps {
    api: ApiEngine
}

interface TradingPointsPageContentState {
    tradingPoints: any[];
    showTradingPoints: any[];
    filter: string;
    activeTradingPoint: any;
    partners: any[];
    activePartner?: any
}

export default class TradingPointsPageContent extends React.Component<TradingPointsPageContentProps, TradingPointsPageContentState> {

    constructor(props: TradingPointsPageContentProps) {
        super(props);
        this.state = {
            tradingPoints: [],
            showTradingPoints: [],
            filter: "",
            activeTradingPoint: null,
            partners: [],
            activePartner: null
        }
        this.getTradingPoints = this.getTradingPoints.bind(this);
    }

    componentDidMount() {
        this.getTradingPoints();
    }

    extract(companies: any[]): any[] {
        let res = [];
        for(let c of companies) {
            if (!c.partner) continue;
            if (res.map((x: any) => {
                return x.id
            }).indexOf(c.partner.id) === -1){
                c.partner.name = `${c.partner.last_name} ${c.partner.first_name} ${c.partner.middle_name}`;
                res.push(c.partner);
            }
        }
        return res;
    }


    getTradingPoints() {
        const url = "/api/companies";
        const me = this;
        this.props.api.asyncFetch(url, {}).then((response) => {
            let partners = me.extract(response.companies)
            this.setState({ partners: partners, activePartner: null, tradingPoints: response.companies, showTradingPoints: response.companies });
        });
    }

    render() {
        const me = this;
        return <div className={"trading-points-page-content"}>
            { me.state.partners.length > 0 &&
                <>
                    <div key={`${me.state.activePartner ? JSON.stringify(me.state.activePartner) : ""} `} className={"partners-list"}>
                        <h3>Партнер:&nbsp;</h3>
                        {
                            me.state.partners.map((p, pIndex) => {
                                return <p
                                    className={`${me.state.activePartner && (me.state.activePartner.id === p.id ? 'active-partner' : '')}`}
                                    onClick={() => {me.setState({activePartner: p, activeTradingPoint: null})}}
                                    key={`partner-${pIndex} `}>{p.last_name} {p.first_name} {p.middle_name}</p>
                            })
                        }
                    </div>
                    <h1>{me.state.activePartner?.name}</h1>
                </>
            }
            <div className="trading-points-list-container">
                <div className={"trading-point-chooser"}>
                    <div className={"search-bar"}>
                        <input type="text" placeholder="Фильтр" value={me.state.filter} onChange={(e) => me.setState({
                            filter: e.target.value,
                            showTradingPoints: me.state.tradingPoints.filter((tradingPoint) => tradingPoint.title.toLowerCase().includes(e.target.value.toLowerCase()))
                        })}/>
                    </div>
                    <div className="trading-points-list">
                        { me.state.showTradingPoints.map((tradingPoint) => {

                            if (me.state.activePartner) {
                                if (tradingPoint.partner?.id !== me.state.activePartner.id) {
                                    return null;
                                }
                            }

                            return <div
                                className={`trading-point-button ${me.state.activeTradingPoint?.id === tradingPoint.id ? "trading-point-active" : ""}`}
                                key={tradingPoint.id} onClick={() => {
                                me.setState({activeTradingPoint: tradingPoint});
                            }}>
                                <div className={"metrics"}>
                                    <h5>{tradingPoint.title}</h5>
                                    <p className={"target-plan"}><i className={"fal fa-ruble-sign"}/>{tradingPoint.targetPlan ? tradingPoint.targetPlan.toLocaleString() : "-"}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </div>

                {me.state.activeTradingPoint &&
                    <TradingPointComponent
                        key={me.state.activeTradingPoint.id}
                        tradingPoint={me.state.activeTradingPoint}
                        api={me.props.api}/>
                }
            </div>
        </div>
    }
}