import React from "react";
import "./style/small-main-metric-widget.scss";
import { ApiEngine } from "api-engine";
import CircularDial from "../dials/circular/CircularDial";
import ModalWindow from "../../../layout/modal/ModalWindow";
import FullMetricWidget from "../full_metric_widget/FullMetricWidget";

interface SmallMainMetricWidgetProps {
    metric: any
    api: ApiEngine
}

interface SmallMainMetricWidgetState {  
    dateInterval: string,
    dateStart: Date,
    dateEnd: Date,
    value: number | null,
    targetValue: number | null,
    lessIsBetter: boolean,
    fullScreenView: boolean
}

export default class SmallMainMetricWidget extends React.Component<SmallMainMetricWidgetProps, SmallMainMetricWidgetState> {
    constructor(props: SmallMainMetricWidgetProps) {
        super(props);

        this.state = {
            dateInterval: "day",
            dateStart: new Date(new Date().setDate(new Date().getDate() - 3)),
            dateEnd: new Date(),
            value: null,
            targetValue: null,
            lessIsBetter: false,
            fullScreenView: false
        }

        this.getNewData = this.getNewData.bind(this);
    }

    componentDidMount() {
        this.getNewData();
    }

    getNewData() {
        const me = this;
        let metricsUrl = `/api/partners/general_metrics/get?metric=`;
        metricsUrl += encodeURI(me.props.metric.title);
        if (me.state.dateStart) {
            console.log('dateStart type:', typeof me.state.dateStart);
            metricsUrl += "&date_start=" + new Date(me.state.dateStart).toISOString();
        }
        if (me.state.dateEnd) {
            metricsUrl += "&date_end=" + new Date(me.state.dateEnd).toISOString();
        }
        me.props.api.asyncFetch(metricsUrl, {}).then((res: any) => {
            // alert(JSON.stringify(res));
            if (res.value && res.value.length > 0) {
                let value = res.value[res.value.length - 1].value;
                let targetValue = res.targetValue;
                if (targetValue == null) {
                    targetValue = value + 1;
                }

                me.setState({value: value, targetValue: targetValue, lessIsBetter: res.lessIsBetter});
            }
        });
    }


    render() {
        const me = this;
        const value = me.state.value || 0;
        const targetValue = this.state.targetValue || 0;
        if (isNaN(targetValue) || typeof targetValue === 'string') {
            return null;
        }
        let maximumValue = (
            !me.state.lessIsBetter && (value > targetValue)) ?
                value
                :
                targetValue;
        console.log("=========");
        console.log(maximumValue);
        console.log(targetValue);
        console.log(value);

        return <><div className={"small-main-metric-widget"} onClick={() => {
            if (window.innerWidth < 768) return;
            me.setState(
                {
                    fullScreenView: !me.state.fullScreenView
                }
            )
        }}>
            { me.state.targetValue != undefined && me.state.value != undefined && 
                <CircularDial
                    gradient={true}
                    value={value}
                    maxValue={maximumValue}
                    lessIsBetter={me.state.lessIsBetter}
                /> 
            }
            <h1 className={"caption"}>{me.props.metric.title}</h1>

            {/* <h2 className={"value"}>{me.state.value?.toLocaleString()} / {me.state.targetValue?.toLocaleString()}</h2> */}
        </div>
        { me.state.fullScreenView &&
            <ModalWindow onClick={() => {
                me.setState({fullScreenView: false})
            }}>
                <div className={"widget-view-content"} onClick={(e) => {
                    e.stopPropagation()}
                }>
                    <FullMetricWidget metric={{
                        showNetworkValue: me.props.metric.showNetworkValue,
                        title: me.props.metric.title,
                        value: [],
                        data: [],
                    }} tradingPoint={null} api={me.props.api}
                    customBaseUrl = {"/api/partners/general_metrics/get?metric="}
                    />
                </div>
            </ModalWindow>
        }
        </>;
    }
}