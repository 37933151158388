import React from "react";
import "./style/menu-button.scss";
import {Link, useNavigate} from "react-router-dom";

interface MenuButtonProps {
  content: any;
  showTitle: boolean;
  callback: any;
}
interface MenuButtonState {
}

export default function MenuButton(props: MenuButtonProps) {
  const navigate = useNavigate();
  return <div className={"menu-button"} onClick={(event) => {
    try {navigator.vibrate(100);} catch (e) {}
      if (props.content.confirm) {
        const confirm = window.confirm(props.content.confirm);
        if (!confirm) {
          event.stopPropagation();
          return
        }
      }
      if ((props.content.href.indexOf("http://") > -1)
            ||
          props.content.href.indexOf("https://") > -1) {
        window.open(props.content.href, '_blank');
        return;
      }
      navigate(props.content.href);
      if (props.callback) {
        props.callback();
      }
      // document.location.href = props.content.href;
  }}>
    <i className={props.content.faIcon}/>
    { props.showTitle &&
      <span className={"title"}>{props.content.title}</span>
    }
    <span className={props.showTitle ? "amount" : "amount-closed"}
          style={props.content.amountImportant ? {color: "var(--green-500)"} : {}}>{props.content.amount}</span>
  </div>;
}