import React from "react";
import "./style/chat-container.scss";
import { ApiEngine } from "api-engine";
import MessageContainer from "./message-container/common/MessageContainer";
import MessageContainerSkeleton from "./message-container/common/MessageContainerSkeleton";

interface ChatContainerProps {
    chat: any;
    api: ApiEngine;
    closeAction?: any;
    hideCloseButton?: boolean;
}

interface ChatContainerState {
    messages: any[];
    messagesLoading: boolean;
    message: any;
    sendingMessage: boolean;
    authorColors: any;
}

const chatColors = [
    "#00abff",
    // "#008080",
    // "#808080",
    // "#808000",
    // "#008080",
    // "#800080",
    "#4B0082",
    "#483D8B",
    "#8B4513",
    "#2F4F4F",
    "#556B2F",
    "#B8860B",
    "#8B0000"
]

export default class ChatContentContainer extends React.Component<ChatContainerProps, ChatContainerState> {
    messagesPollingTimeout: any
    messagesContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
    constructor(props: ChatContainerProps) {
        super(props);
        this.state = {
            messages: [],
            messagesLoading: true,
            message: "",
            sendingMessage: false,
            authorColors: {}
        };
        this.getMessages = this.getMessages.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    sendMessage() {
        const me = this;
        const url = `/api/dialogs/${me.props.chat.id}/messages`;
        const body = me.state.message
        if (!body.content) return;
        me.setState({sendingMessage: true}, () => {
            me.props.api.asyncFetch(url, {
                method: "POST",
                body: JSON.stringify(body)
            }).then((res) => {
                me.setState({message: {content: "", sending: false}, sendingMessage: false}, () => {
                    me.getMessages();
                });
            });
        });
    }

    componentDidMount() {
        const me = this;
        me.getMessages();
    }

    componentWillUnmount() {
        if (this.messagesPollingTimeout) clearTimeout(this.messagesPollingTimeout);
    }

    getMessages() {
        if (this.messagesPollingTimeout) clearTimeout(this.messagesPollingTimeout);
        const me = this;
        const url = `/api/dialogs/${me.props.chat.id}/messages`;
        me.props.api.asyncFetch(url, {}).then((res) => {
            let colorIndex = 0;
            let messages = res.dialogMessages ? res.dialogMessages : ([] as any[]);
                if (me.state.messages.length != messages.length) {
                    let messagesToAdd = messages.reverse();
                    messagesToAdd.forEach((message: any) => {
                        message.createdAt = (new Date(message.createdAt)).toLocaleString();
                        if (!me.state.authorColors[message.author]) {
                            me.state.authorColors[message.author] = chatColors[colorIndex % chatColors.length];
                            colorIndex++;
                        }
                        message.authorColor = me.state.authorColors[message.author];
                    });
                    me.setState({messages: messagesToAdd, messagesLoading: false}, () => {
                        me.messagesContainerRef.current?.scrollTo({
                            top: me.messagesContainerRef.current?.scrollHeight,
                            behavior: 'smooth'
                        });
                        me.messagesPollingTimeout = setTimeout(() => {
                            me.getMessages();
                        }, 10000);
                    });
                } else {
                    me.setState({messagesLoading: false}, () => {
                        me.messagesPollingTimeout = setTimeout(() => {
                            me.getMessages();
                        }, 10000);
                    });
                }
                
        });
    }

    render() {
        const me = this;
        return <div className={"chat-container"}
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onKeyUp={(e) => {
                        if (e.key == "Enter") me.sendMessage();
                    }}
        >
            <h4 className={"dialog-title"}>
                <span>{me.props.chat.title}</span>
                { !me.props.hideCloseButton && <i onClick={() => {
                    if (me.props.closeAction) me.props.closeAction();
                }} className={"fal fa-times"} /> }
            </h4>
            <div className={"messages-container"} ref={me.messagesContainerRef}>
                { me.state.messagesLoading ? [1,2,3,4].map((item) => {
                    return <MessageContainerSkeleton key={item} />
                })
                :
                me.state.messages.map((message, index) => (
                    <MessageContainer api={me.props.api} color={me.state.authorColors[message.author]} key={index} message={message} isLastMessage={index == me.state.messages.length - 1} onButtonClick={(button: string) => {
                        me.setState({message: {content: button, sending: false}, sendingMessage: false}, () => {
                            me.sendMessage();
                        });
                    }} />
                ))}
                { !me.state.messagesLoading && (me.state.messages.length == 0) &&
                    <p className={"no-messages"}>Сообщений пока нет</p>
                }
            </div>
            <div className={"message-input-container"}
                style={me.state.sendingMessage ? {opacity: 0.5, pointerEvents: "none"} : {}}
            >
                <input type="text" 
                    value={me.state.message ? me.state.message.content : ""}
                    placeholder="Введите сообщение" 
                    onChange={(e) => me.setState({message: {content: e.target.value}})} />
                
                <i 
                    onClick={() => me.sendMessage()}
                    className={`fas fa-paper-plane ${me.state.message.content ? "active" : ""}`}></i>
            </div>
        </div>;
    }
}



