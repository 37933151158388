import React from "react";

import User from "../../domains/actors/users/User";
import {ApiEngine} from "api-engine";
import "./style/dialogs-page-content.scss";
import ModalWindow from "../../layout/modal/ModalWindow";
import ChatContentContainer from "../../domains/chat/layout/components/chat-container/common/ChatContentContainer";

interface DialogsPageContentProps {
    user: User;
    api: ApiEngine;
}

export default class DialogsPageContent extends React.Component<DialogsPageContentProps, any> {
    chatsUpdateTimeout: any = null;

    constructor(props: DialogsPageContentProps) {
        super(props);
        this.state = {
            activeChat: null,
            listOfChats: [],
            shownChats: [],
            listOfChatsLoading: true,
            searchQuery: ""
        }
        this.getListOfChats = this.getListOfChats.bind(this);
        this.openChat = this.openChat.bind(this);
    }

    componentDidMount() {
        const me = this;
        setTimeout(
            () => {
                me.getListOfChats();
            }, 1000
        )
    }

    componentWillUnmount() {
        clearTimeout(this.chatsUpdateTimeout);
    }   

    getListOfChats() {
        const me = this;
        // setTimeout(() => {
        //     me.setState({listOfChats: listOfChatsMock, listOfChatsLoading: false});
        // }, 2000);
        // return
        const url = "/api/dialogs/";
        this.props.api.asyncFetch(url, {}).then((response) => {
            const dialogs = response.dialogs ? response.dialogs : [];
            console.log(dialogs);
            me.setState({listOfChats: dialogs, listOfChatsLoading: false}, () => {
                me.chatsUpdateTimeout = setTimeout(() => {
                    me.getListOfChats();
                }, 10000);
            });
        });
    }

    openChat(chat: any) {
        const me = this;
        me.setState({activeChat: chat});
    }

    render() {
        const me = this;
        return <div className={"dialogs-page-content"}>
            <h1>Коммуникация с УК</h1>
            <div className={"chat-wrapper"}>
                <div className={"chat-buttons-container"}>
                    <input type="text" placeholder="Поиск по названию" onChange={(e) => {
                        me.setState({searchQuery: e.target.value});
                    }}/>
                    {
                    me.state.listOfChatsLoading ?
                    <p className="loading-chats">Загрузка чатов</p>
                    :
                    me.state.listOfChats.map((chat: any, chatIndex: number) => {
                        if (me.state.searchQuery && !chat.title.toLowerCase().includes(me.state.searchQuery.toLowerCase())) {
                            return null;
                        }
                        return <div key={chat.id}
                                    onClick={() => me.openChat(chat)}
                                    className={`chat-button ${me.state.activeChat?.id === chat.id ? "chat-button--active" : ""}`}>
                            <p>{chat.title}</p>
                            {/* <ChatOpeningButton api={me.props.api} chat={chat}
                                                onTouch={() => me.setState({
                                                    isTouch: true
                                                }, () => {
                                                    me.openChat(chat.id)
                                                })}
                                                onClick={() => me.openChat(chat)}/>
                                                 */}
                        </div>
                        })
                    }
                </div>
                <div className={"chat-container"}>
                    { me.state.activeChat ?
                        window.innerWidth > 768 ?
                            <ChatContentContainer chat={me.state.activeChat} api={me.props.api} hideCloseButton={true}/>
                        :
                            <ModalWindow onClick={() => {me.setState({activeChat: null})}}>
                                <ChatContentContainer closeAction={() => {
                                    me.setState({activeChat: null})
                                }} chat={me.state.activeChat} api={me.props.api} hideCloseButton={false}/>
                            </ModalWindow>
                    :
                    <div className={"chat-container-empty-state"}>
                        <p>Выберите чат</p>
                    </div>
                    }
                </div>
            </div>
            
        </div>;
    }
}