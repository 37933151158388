import React, {useState} from "react";
import "./style/message-container-style.scss";
import OnVisible from "react-on-visible";
import {ApiEngine} from "api-engine";
import {VisibilityObserver} from "reactjs-visibility";

interface MessageContainerProps {
    message: any;
    onButtonClick?: (button: string) => void;
    isLastMessage?: boolean;
    color?: string;
    api: ApiEngine;
}

const MessageContainer: React.FC<MessageContainerProps> = (props) => {

    const extractButtons = (content: string) => {
        const buttons = content.match(/\\c{(.*?)}/g)?.map(button => button.slice(3, -1));
        return buttons;
    };

    const removeButtons = (content: string) => {
        return content.replace(/\\c{(.*?)}/g, "");
    };

    const [seen, setSeen] = useState(false)

    const [state] = React.useState({
        content: removeButtons(props.message.content),
        author: props.message.author,
        createdAt: props.message.createdAt,
        button: extractButtons(props.message.content),
    });



    if (state.content.includes("Краткий RECAP")) {
        return null;
    }
    if (state.content.startsWith("Групповой чат.")) {
        return null;
    }
    if (state.content.endsWith("создал чат")) {
        return null;
    }
    if (state.content === "#ЗАВЕРШИТЬ") {
        return (
            <VisibilityObserver onChangeVisibility={(_e) => {
                // console.log(props.message);
                if (!seen) {
                    setSeen(true)
                    props.api.asyncFetchWithoutQueing(`/api/dialogs/messages/${props.message.id}/mark_read`, {}, 0).then(async (response) => {
                    })
                }
            }}>
                <div className={"message-container"} style={{backgroundColor: props.color}}>
                    <div className={"first-line"}>
                        <p className={"author"}>{props.message.author === "Невозможно определить" ? "🤖" : props.message.author}</p>
                        <p className={"created-at"}>{props.message.createdAt}</p>
                    </div>
                    <div className={"second-line"}>
                        <p className={"content"}>Оператор вышел из чата</p>
                    </div>
                    { seen ?
                        <i className={"read-status fal fa-check-double"}></i> :
                        <i className={"read-status fal fa-check"}></i>
                    }
                </div>
            </VisibilityObserver>
        );
    }

    return (
        <VisibilityObserver onChangeVisibility={(_e) => {
            if (!seen) {
                setSeen(true)
                props.api.asyncFetchWithoutQueing(`/api/dialogs/messages/${props.message.id}/mark_read`, {}, 0).then(async (response) => {
                })
            }
        }}>
            <div className={"message-container"} style={{backgroundColor: props.color}}>
                <div className={"first-line"}>
                    <p className={"author"}>{props.message.author === "Невозможно определить" ? "🤖" : props.message.author}</p>
                    <p className={"created-at"}>{props.message.createdAt}</p>
                </div>
                <div className={"second-line"}>
                    <p className={"content"}>{state.content}</p>
                </div>
                {props.isLastMessage && state.button && state.button.map((button: any, index: number) => {
                    return (
                        <button key={index} onClick={() => props.onButtonClick && props.onButtonClick(button)}>
                            <p>{button}</p>
                        </button>
                    );
                })}
                {seen ?
                    <i className={"read-status fal fa-check-double"}></i> :
                    <i className={"read-status fal fa-check"}></i>
                }
            </div>
        </VisibilityObserver>
    );
};

export default MessageContainer;